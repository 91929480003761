/* eslint-disable */
import { createMachine, fromPromise } from "xstate";
export const machine = createMachine(
  {
    id: "login",
    initial: "pendingEmailInput",
    states: {
      pendingEmailInput: {
        on: {
          submit: {
            target: "checkEmailInProgress",
          },
        },
      },
      checkEmailInProgress: {
        meta: {
          isLoading: true,
        },
        entry: {
          type: "hideError",
        },
        invoke: {
          input: {},
          src: "checkEmail",
          id: "checkEmail",
          onDone: [
            {
              target: "signin",
              guard: "isEmailExisting",
            },
            {
              target: "signup",
            },
          ],
          onError: [
            {
              target: "pendingEmailInput",
              actions: {
                type: "showError",
                params: {
                  error: "Internal server error :-(",
                },
              },
            },
          ],
        },
      },
      signin: {
        entry: [
          {
            type: "changeStepsForSignin",
          },
          {
            type: "incrementStep",
          },
          {
            type: "showSignin",
          },
        ],
        initial: "pendingPasswordInput",
        states: {
          pendingPasswordInput: {
            on: {
              submit: {
                target: "loginWithEmailAndPasswordInProgress",
              },
            },
          },
          loginWithEmailAndPasswordInProgress: {
            meta: {
              isLoading: true,
            },
            entry: {
              type: "hideError",
            },
            invoke: {
              input: {},
              src: "loginWithEmailAndPassword",
              id: "loginWithEmailAndPassword",
              onDone: [
                {
                  target: "done",
                  guard: "isSuccess",
                },
                {
                  target: "pendingPasswordInput",
                  actions: {
                    type: "showError",
                    params: {
                      error: "Incorrect password.",
                    },
                  },
                },
              ],
              onError: [
                {
                  target: "pendingPasswordInput",
                  actions: {
                    type: "showError",
                    params: {
                      error: "Nope :-(",
                    },
                  },
                },
              ],
            },
          },
          done: {
            entry: {
              type: "sendGaEvent",
              params: {
                event: "login",
              },
            },
            type: "final",
          },
        },
        onDone: {
          target: "fetchWhoAmIInProgress",
        },
      },
      signup: {
        entry: [
          {
            type: "changeStepsForSignup",
          },
          {
            type: "incrementStep",
          },
          {
            type: "showSignup",
          },
        ],
        initial: "pendingPasswordInput",
        states: {
          pendingPasswordInput: {
            on: {
              submit: {
                target: "registerWithEmailAndPasswordInProgress",
              },
            },
          },
          registerWithEmailAndPasswordInProgress: {
            meta: {
              isLoading: true,
            },
            entry: {
              type: "hideError",
            },
            invoke: {
              input: {},
              src: "registerWithEmailAndPassword",
              id: "registerWithEmailAndPassword",
              onDone: [
                {
                  target: "pendingVerificationTokenInput",
                  actions: {
                    type: "incrementStep",
                  },
                },
              ],
              onError: [
                {
                  target: "pendingPasswordInput",
                  actions: {
                    type: "showError",
                    params: {
                      error: "Cannot send verification token :-(",
                    },
                  },
                },
              ],
            },
          },
          pendingVerificationTokenInput: {
            on: {
              submit: {
                target: "loginWithTokenInProgress",
              },
              verificationTokenRequested: {
                target: "registerWithEmailAndPasswordInProgress",
                actions: {
                  type: "decrementStep",
                },
              },
            },
          },
          loginWithTokenInProgress: {
            meta: {
              isLoading: true,
            },
            entry: {
              type: "hideError",
            },
            invoke: {
              input: {},
              src: "loginWithToken",
              id: "loginWithToken",
              onDone: [
                {
                  target: "done",
                  guard: "isSuccess",
                },
                {
                  target: "pendingVerificationTokenInput",
                  actions: {
                    type: "showError",
                    params: {
                      error: "Incorrect token.",
                    },
                  },
                },
              ],
              onError: [
                {
                  target: "pendingVerificationTokenInput",
                  actions: {
                    type: "showError",
                    params: {
                      error: "Failed to create user.",
                    },
                  },
                },
              ],
            },
          },
          done: {
            entry: {
              type: "sendGaEvent",
              params: {
                event: "sign_up",
              },
            },
            type: "final",
          },
        },
        onDone: {
          target: "fetchWhoAmIInProgress",
        },
      },
      fetchWhoAmIInProgress: {
        meta: {
          isLoading: true,
        },
        invoke: {
          input: {},
          src: "fetchWhoAmI",
          id: "fetchWhoAmI",
          onDone: [
            {
              target: "createCheckoutSessionUrlInProgress",
              guard: "isPurchasePending",
              actions: {
                type: "incrementStep",
              },
            },
            {
              target: "loggedIn",
            },
          ],
          onError: [
            {
              target: "reloadInProgress",
            },
          ],
        },
      },
      createCheckoutSessionUrlInProgress: {
        meta: {
          isLoading: true,
        },
        invoke: {
          input: {},
          src: "createCheckoutSessionUrl",
          id: "createCheckoutSessionUrl",
          onDone: [
            {
              target: "loggedIn",
              description:
                "This is only achieved in case of error,\n\nas we expect `createCheckoutSessionUrl`\n\nto redirect inside the invocation.",
            },
          ],
        },
      },
      loggedIn: {
        entry: {
          type: "closeWindow",
        },
        type: "final",
      },
      reloadInProgress: {
        meta: {
          isLoading: true,
        },
        after: {
          "3000": {
            target: "#login.fetchWhoAmIInProgress",
            actions: [],
            meta: {},
          },
        },
      },
    },
  },
  {
    actions: {
      hideError: ({ context, event }) => {},
      showError: ({ context, event }) => {},
      showSignin: ({ context, event }) => {},
      showSignup: ({ context, event }) => {},
      closeWindow: ({ context, event }) => {},
      decrementStep: ({ context, event }) => {},
      incrementStep: ({ context, event }) => {},
      changeStepsForSignin: ({ context, event }) => {},
      changeStepsForSignup: ({ context, event }) => {},
      sendGaEvent: ({ context, event }) => {},
    },
    actors: {
      checkEmail: fromPromise({
        /* ... */
      }),
      fetchWhoAmI: fromPromise({
        /* ... */
      }),
      loginWithToken: fromPromise({
        /* ... */
      }),
      createCheckoutSessionUrl: fromPromise({
        /* ... */
      }),
      loginWithEmailAndPassword: fromPromise({
        /* ... */
      }),
      registerWithEmailAndPassword: fromPromise({
        /* ... */
      }),
    },
    guards: {
      isSuccess: ({ context, event }, params) => {
        return false;
      },
      isEmailExisting: ({ context, event }, params) => {
        return false;
      },
      isPurchasePending: ({ context, event }, params) => {
        return false;
      },
    },
    delays: {},
  },
);
