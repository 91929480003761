import { Reactive, reactive, watch } from 'vue';
import { post } from './post.js';
import { API_PREFIX } from './constants.js';
import { PANES, RenderFormats } from './paneConstants.js';
import { presets } from './presets.js';
import {
  translatePaneObjectToSingleImageApiRequestStructure,
  getDistances,
  generateDehancerStatesFromPaneObject,
  type PaneObject,
  type DehancerImageState
} from './paneProcessor.js';
import { LastImagePublic } from '^/commonTypes.js';
import hashSum from 'hash-sum';
import { useDebounceFn } from '@vueuse/core';
import { open as showErrorPage } from './errorPage.js';

export type ImageArray = Reactive<string[]>;

type RequestRenderSingleImageServerResponse = {
  success: false;
  url: never;
} | {
  success: true;
  url: string;
};

type RequestImagesRequest = {
  imageId: string;
  size: 'small' | 'large';
  states: DehancerImageState[];
};

type RequestImagesServerResponse = {
  success: false;
  images?: never;
} | {
  success: true;
  images: string[];
};

type RenderExportImageServerResponse = {
  success: false;
  isDeauthorized: true;
} | {
  success: false;
  isDeauthorized: false | undefined;
  error: string;
} | {
  success: true;
  isDeauthorized?: never;
  error?: never;
  lastImage: LastImagePublic;
  url: string;
  filename: string;
};

type RequestRenderSingleImageFunction = (data: any) => Promise<RequestRenderSingleImageServerResponse>;
let requestRenderSingleImageWithDebounce: RequestRenderSingleImageFunction | null = null;

const cachePaneByKey = new Map<string, ImageArray>();
const largeImagePaneHasBeenRequestedByKey = new Set<string>();

const MAX_SINGLE_IMAGES_CACHE_COUNT = 500; // actually it's quite light on memory
let singleImagesCacheByJsonString: Record<string, string> = {};
let singleImagesCacheKeys: string[] = [];

export function flushCache() {
  cachePaneByKey.clear();
  largeImagePaneHasBeenRequestedByKey.clear();
  singleImagesCacheByJsonString = {};
  singleImagesCacheKeys = [];
}

function cleanupSingleImagesCache() {
  if (singleImagesCacheKeys.length < MAX_SINGLE_IMAGES_CACHE_COUNT) {
    return;
  }

  const keyToDelete = singleImagesCacheKeys.shift();
  delete singleImagesCacheByJsonString[keyToDelete!];
}

function getPaneKey(paneObject: PaneObject): string {
  const allowedKeys = [
    'mode',
    'imageId',
    'presetId',
    'tint',
    'temperature',
    'exposure',
    'contrast',
    'color_boost',
    'bloom',
    'halation',
    'grain',
    'is_bloom_enabled',
    'is_grain_enabled',
    'is_halation_enabled',
    'is_vignette_enabled',

    'vignette_exposure',
    'vignette_feather',
    'vignette_size',

    'sequence'
  ];

  const mainPropertyKeyNames = PANES[paneObject.mode].mainPropertyKeyNames;
  const paneKeys = allowedKeys.filter(key => !mainPropertyKeyNames.includes(key));

  const keyObject: Record<string, any> = {};
  Object.keys(paneObject).forEach(key => {
    if (paneKeys.includes(key)) {
      keyObject[key] = paneObject[key as keyof PaneObject];
    }
  });

  return hashSum(keyObject);
}

export function requestSmallImages(paneObject: PaneObject): ImageArray {
  const key = getPaneKey(paneObject);

  if (cachePaneByKey.has(key)) {
    return cachePaneByKey.get(key)!;
  }

  const imageArray: ImageArray = reactive([]);

  const states = generateDehancerStatesFromPaneObject(paneObject);

  const requestImagesRequest: RequestImagesRequest = {
    imageId: paneObject.imageId,
    size: 'small',
    states: states!
  };

  requestImages(requestImagesRequest, imageArray, paneObject);
  cachePaneByKey.set(key, imageArray);

  return imageArray;
}

export function requestLargeImages(paneObject: PaneObject) {
  const key = getPaneKey(paneObject);

  if (!cachePaneByKey.has(key)) {
    // How did we get here?
    return;
  }

  if (largeImagePaneHasBeenRequestedByKey.has(key)) {
    return;
  }

  if (!cachePaneByKey.has(key)) {
    // How did we get here?
    return;
  }

  const imageArray = cachePaneByKey.get(key);

  const states = generateDehancerStatesFromPaneObject(paneObject);

  const requestImagesRequest: RequestImagesRequest = {
    imageId: paneObject.imageId,
    size: 'large',
    states: states!
  };

  requestImages(requestImagesRequest, imageArray!, paneObject);

  largeImagePaneHasBeenRequestedByKey.add(key);
}

export async function renderPreviewImage(paneObject: PaneObject): Promise<RequestRenderSingleImageServerResponse> {
  cleanupSingleImagesCache();

  const processedPaneObject: any = { // FIXME any
    ...translatePaneObjectToSingleImageApiRequestStructure(paneObject),
    state: null
  };

  processedPaneObject.state = processedPaneObject.states[0];
  delete processedPaneObject.states;
  delete processedPaneObject.size;

  const key = JSON.stringify(processedPaneObject);
  if (singleImagesCacheByJsonString[key]) {
    return {
      success: true,
      url: singleImagesCacheByJsonString[key]
    };
  }

  const json = await requestRenderSingleImage(processedPaneObject);
  if (!json?.success) {
    return json;
  }

  singleImagesCacheByJsonString[key] = json.url;
  singleImagesCacheKeys.push(key);

  return json;
}

export async function renderExportImage(paneObject: PaneObject, format: RenderFormats): Promise<RenderExportImageServerResponse> {
  const processedPaneObject: any = {
    ...translatePaneObjectToSingleImageApiRequestStructure(paneObject)
  };

  processedPaneObject.state = processedPaneObject.states[0];
  processedPaneObject.format = format;
  delete processedPaneObject.states;
  delete processedPaneObject.size;

  return await requestRenderExportImage(processedPaneObject);
}

export function requestSingleLargeImage(paneObject: PaneObject, imageArray: ImageArray, imageIndex: number, isFast = false) {
  cleanupSingleImagesCache();

  const processedPaneObject: any = {
    ...translatePaneObjectToSingleImageApiRequestStructure(paneObject)
  };

  processedPaneObject.state = processedPaneObject.states[0];
  delete processedPaneObject.states;
  delete processedPaneObject.size;

  const key = JSON.stringify(processedPaneObject);

  if (singleImagesCacheByJsonString[key]) {
    imageArray[imageIndex] = singleImagesCacheByJsonString[key];
    return;
  }

  const functionToCall = isFast ? requestRenderSingleImage : requestRenderSingleImageWithDebounce;

  // we must return imageArray immediately while the request should continue async
  functionToCall!(processedPaneObject).then((result: RequestRenderSingleImageServerResponse) => {
    if (!result) { // debounced
      return;
    }

    if (!result.success) {
      return;
    }

    singleImagesCacheByJsonString[key] = result.url;
    singleImagesCacheKeys.push(key);

    imageArray[imageIndex] = result.url!;
  });
}

async function requestImagesImplementation(processedPaneObject: RequestImagesRequest, imageArray: ImageArray, paneObject: PaneObject): Promise<void> {
  const { json, status }: { json: RequestImagesServerResponse, status: number | null } = await post({
    url: API_PREFIX + '/image/previews/' + processedPaneObject.imageId, // FIXME extract imageId into arguments?
    data: processedPaneObject,
    isImageRequest: true
  });

  if (!json?.success) {
    if (status === 404) {
      showErrorPage("404", "Image not found");

    } else if (status === 403) {
      showErrorPage("403", "Access denied to that image");

    } else if (status === 500) {
      showErrorPage("500", "Internal server error");

    } else if (status === 999) {
      showErrorPage("Timeout", "We have spent too much time and failed.");

    } else {
      showErrorPage('Oops', "Unknown error");
    }

    return;
  }

  // draw nearest images first
  const distances = getDistances(paneObject);

  for (let i = 0; i < json.images.length; i++) {
    if (distances[i] > 1.3) {
      continue;
    }
    imageArray[i] = json.images[i];
  }

  await new Promise(resolve => setTimeout(resolve, 150));

  for (let i = 0; i < json.images.length; i++) {
    if (distances[i] <= 1.3) {
      continue;
    }
    imageArray[i] = json.images[i];
  }
}

const requestRenderSingleImage: RequestRenderSingleImageFunction = async (data: any) => { // FIXME any
  const { json, status } = await post({ // FIXME errors are not supported here at all. Review code and fix it.
    url: API_PREFIX + '/image/render/' + data.imageId, // FIXME extract imageId into arguments?
    data,
    isImageRequest: true
  });

  if (!json?.success) {
    if (status === 404) {
      showErrorPage("404", "Image not found");

    } else if (status === 403) {
      showErrorPage("403", "Access denied to that image");

    } else if (status === 500) {
      showErrorPage("500", "Internal server error");

    } else if (status === 999) {
      showErrorPage("Timeout", "We have spent too much time and failed.");

    } else {
      showErrorPage('Oops', "Unknown error");
    }
  }

  return json as RequestRenderSingleImageServerResponse;
};

async function requestRenderExportImage(data: any): Promise<RenderExportImageServerResponse> { // FIXME any
  const { json, status } = await post({ // FIXME errors are not supported here at all. Review code and fix it.
    url: API_PREFIX + '/image/export/' + data.imageId, // FIXME extract imageId into arguments?
    data,
    isImageRequest: true
  });

  if (!json?.success) {
    if (status === 404) {
      showErrorPage("404", "Image not found");

    } else if (status === 403) {
      showErrorPage("403", "Access denied to that image");

    } else if (status === 500) {
      showErrorPage("500", "Internal server error");

    } else if (status === 999) {
      showErrorPage("Timeout", "We have spent too much time and failed.");

    } else {
      showErrorPage('Oops', `Unknown error (status ${status})`);
    }
  }

  return json;
}

requestRenderSingleImageWithDebounce = useDebounceFn(requestRenderSingleImage, 400);

const requestImages = useDebounceFn(requestImagesImplementation, 500);

watch(
  presets,
  () => PANES['preset'].itemsTotal = presets.value?.length || 0,
  { immediate: true }
);
