import { ShallowRef, shallowRef } from 'vue';

type ButtonCallbackFunction = () => void;

export const isOpen = shallowRef(false);
export const shouldShowButton = shallowRef(false);
export const buttonCallback: ShallowRef<ButtonCallbackFunction | null> = shallowRef(null);
export const message: ShallowRef<string | null> = shallowRef(null);
export const progress: ShallowRef<number | null> = shallowRef(null);

export function open(_message: string | null = null, _progress:number | null = null) {
  shouldShowButton.value = false;
  progress.value = _progress;
  message.value = _message;
  isOpen.value = true;
}

export function close() {
  isOpen.value = false;
}

export function showButton(callback: ButtonCallbackFunction | null) {
  buttonCallback.value = callback;
  shouldShowButton.value = true;
}
