<template>
  <div class="flex flex-col h-full bg-blueprint">
    <div
      class="shrink-0 bg-main text-white/60 shadow-lg z-20"
      :class="{ 'toolbar-sm': isMobile }"
    >
      <modal-header :is-mobile="isMobile" icon="Crop" title="Crop" is-bw :disabled="isLoading" @close="goBack">
        <div class="mr-auto flex items-center">
          <cropper-button icon="Reset" :disabled="isLoading || !isCropperReady" @click="reset" />
          <cropper-button icon="FlipX" :disabled="isLoading || !isCropperReady" @click="flipHorizontally" />
          <cropper-button icon="FlipY" :disabled="isLoading || !isCropperReady" @click="flipVertically" />
          <cropper-button icon="RotateRight" :disabled="isLoading || !isCropperReady || !isRotateRightButtonEnabled" @click="rotate(90)" />
          <cropper-button icon="Ratio" :disabled="isLoading || !isCropperReady" @click="isModalRatioOpen = !isModalRatioOpen" />

          <cropper-input-angle v-model="angle" class="ml-4" />
        </div>
        <div>
          <button :disabled="isLoading || !isCropperReady" class="btn-sm ml-4 px-6" @click="doCrop">Apply</button>
        </div>
      </modal-header>
    </div>

    <div class="h-full overflow-hidden relative" :class="isLoading ? 'opacity-50' : ''">
      <div class="absolute inset-5">
        <Cropper
          ref="cropper"
          :src="imageUrl"
          :aspect-ratio
          :margin="40"
          @ready="isCropperReady = true"
        />
      </div>

      <modal-crop-ratio
        :is-open="isModalRatioOpen"
        :aspect-ratio
        :image-aspect-ratio
        @close="isModalRatioOpen = false"
        @update="$event => aspectRatio = $event"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ky from 'ky';
import { ref, watch, onBeforeMount, shallowRef, Ref } from 'vue';
// @ts-expect-error cropped is awful
import Cropper from '../components/vue-advanced-cropper/Cropper.vue';
import ModalHeader from '../components/ModalHeader.vue';
import { useRouter } from 'vue-router';
import { isMobile } from '../breakpoints';
import { API_PREFIX } from '../constants';
import { stateByImageId } from '../stateByImageId';
import { angle } from '../crop';
import { post } from '../post';
import { flushCache } from '../panes';
import { cropSequence } from '../cropSequence';
import CropperButton from '../components/CropperButton.vue';
import ModalCropRatio from '../components/ModalCropRatio.vue';
import CropperInputAngle from '../components/CropperInputAngle.vue';

const router = useRouter();

const isLoading = shallowRef(true);

const { imageId } = defineProps<{
  imageId: string;
  originalUrl: string;
  placeholderUrl: string;
}>();

const state = stateByImageId.value[imageId];
if (!state) {
  goBack();
}

const isModalRatioOpen = ref(false);

const isRotateRightButtonEnabled = shallowRef(true);
const cropper: Ref<typeof Cropper | null> = ref(null);
const imageUrl: Ref<string | null> = ref(null);
const ignoreWatcher = ref(false);
const imageCropProperties = shallowRef(null);
const isCropperReady = shallowRef(false);
const aspectRatio: Ref<number | undefined> = ref(undefined);
const imageAspectRatio: Ref<number | undefined> = ref(undefined);

const flip = ref({
  horizontal: false,
  vertical: false
});

onBeforeMount(async () => {
  angle.value = 0;

  isLoading.value = true;

  const json: any = await ky.get(API_PREFIX + '/image/uncropped-large-jpeg/' + imageId).json().catch(() => null);

  if (!json?.success) {
    alert("error");
    goBack();
    return;
  }

  const ii = new Image();

  ii.onload = () => {
    imageUrl.value = json.url;
    imageCropProperties.value = json;
    isLoading.value = false;
  };

  ii.src = json.url;
});

watch(
  [ isCropperReady, imageCropProperties ],
  () => {
    if (!isCropperReady.value || imageCropProperties.value === null) {
      return;
    }

    setCropper(imageCropProperties.value);
    imageCropProperties.value = null;
  },
  { immediate: true }
);

watch(angle, value => {
  if ((value ?? null) !== null && !ignoreWatcher.value) {
    cropper.value?.setAngle(value);
  }

  ignoreWatcher.value = false;
});

function goBack() {
  router.push({ name: 'imagePage', query: { i: imageId } });
}

function setCropper(json: any) {
  const _info = cropper.value.getResult(); // this is the only reason to wait for ready.

  imageAspectRatio.value = _info?.image?.height ? _info.image.width / _info.image.height : undefined;

  flip.value = {
    horizontal: json.flipHorizontal,
    vertical: json.flipVertical
  };

  ignoreWatcher.value = true;
  angle.value = json.rotate;

  cropper.value.flip(flip.value.horizontal, flip.value.vertical, { transitions: false });

  const scaleFactor = json.originalWidth / _info.image.width;

  cropper.value.setCoordinates({
    left: json.areaLeft / scaleFactor,
    top: json.areaTop / scaleFactor,
    width: json.areaWidth / scaleFactor,
    height: json.areaHeight / scaleFactor
  }, { transitions: false });

  cropper.value.setAngle(angle.value);
  cropper.value.debouncedDisableTransitions();
}

let resetAngleTimeout: number | null = null;

function rotate(deg: number) {
  if (!isRotateRightButtonEnabled.value) {
    return;
  }

  if (resetAngleTimeout) {
    clearTimeout(resetAngleTimeout);
  }

  isRotateRightButtonEnabled.value = false;

  ignoreWatcher.value = true;

  let newAngleValue = angle.value + deg;

  if (newAngleValue >= 360) {
    newAngleValue -= 360;

  } else if (newAngleValue <= -360) {
    newAngleValue += 360;
  }

  newAngleValue = Math.floor(newAngleValue / 90) * 90;

  const rotateBy = newAngleValue - angle.value;

  cropper.value.rotate(rotateBy);
  angle.value = newAngleValue;

  resetAngleTimeout = setTimeout(() => isRotateRightButtonEnabled.value = true, 300);
}

async function doCrop() {
  const _info = cropper.value.getResult();

  isLoading.value = true;
  // FIXME error handling?
  await post({
    url: API_PREFIX + '/image/crop/' + imageId,
    data: {
      areaLeft: _info.coordinates.left,
      areaTop: _info.coordinates.top,
      areaWidth: _info.coordinates.width,
      areaHeight: _info.coordinates.height,
      rotate: _info.imageTransforms.rotate,
      flipHorizontal: _info.imageTransforms.flip.horizontal,
      flipVertical: _info.imageTransforms.flip.vertical,
      imageWidth: _info.image.width,
      imageHeight: _info.image.height,
      isImageRequest: true
    }
  });
  isLoading.value = false;

  flushCache();

  cropSequence.value++;

  goBack();
}

function reset() {
  angle.value = 0;
  aspectRatio.value = undefined;
  flip.value = {
    horizontal: false,
    vertical: false
  };
  cropper.value.reset();
}

function flipHorizontally() {
  flip.value = {
    horizontal: !flip.value.horizontal,
    vertical: flip.value.vertical
  };
  cropper.value.flip(true, false);
}

function flipVertically() {
  flip.value = {
    horizontal: flip.value.horizontal,
    vertical: !flip.value.vertical
  };
  cropper.value.flip(false, true);
}
</script>
