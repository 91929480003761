
export const ALLOWED_FILE_TYPES: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/tiff': 'tiff',
  'image/heif': 'heif',
  'image/heic': 'heic',
  'image/avif': 'avif',
  'image/webp': 'webp',
  'image/png': 'png',
  'image/x-adobe-dng': 'dng'
};

const MIME_TYPES_BY_EXTENSION: Record<string, string> = {
  jpg: 'image/jpeg',
  tiff: 'image/tiff',
  heif: 'image/heif',
  heic: 'image/heic',
  avif: 'image/avif',
  webp: 'image/webp',
  png: 'image/png',
  dng: 'image/x-adobe-dng'
};

const MAX_SIZE_MB_DEFAULT = 100;

const MAX_SIZE_MB: Record<string, number> = {
  // larger than the size of a 100MP image from Fuji GFX 100 II but upsized to a square, 8bit color no transparency, no compression
  'image/tiff': 400,

  // iPhone raw max size in DNG format
  'image/x-adobe-dng': 100
};

function getExtensionFromFilename(filename: string): string {
  const lcFilename = filename.toLowerCase();
  const s = lcFilename.match(/\.([^.]+)$/);
  if (!s) {
    return '';
  }

  const extension = s[1];

  if (extension === 'jpeg') {
    return 'jpg';
  }

  if (extension === 'tif') {
    return 'tiff';
  }

  return extension;
}

export type UploadOutput = {
  status: number;
  etags: string[] | null;
};

type UploadInput = {
  file: File | Blob;
  url: string[];
  chunkSize?: number;
  onProgress: (uploaded: number, total: number) => void;
};

export async function uploadMultiple({ file, url, chunkSize, onProgress: onTotalProgress }: UploadInput): Promise<UploadOutput> {
  if (!chunkSize) {
    throw new Error("No chunk size for multipart upload");
  }

  const chunks: {
    blob: Blob;
    partNumber: number;
    url: string;
  }[] = [];

  for (let i = 0; i < url.length; i++) {
    const start = i * chunkSize;

    let end = Math.min(file.size, start + chunkSize);

    if (i === url.length - 1) { // last
      end = file.size;
    }

    chunks.push({
      blob: file.slice(start, end),
      partNumber: i + 1,
      url: url[i]
    });
  }

  const etags: string[] = [];

  const loadedByUrl: Record<string, number> = {};

  const onProgress = (loaded: number, _total: number, _url: string) => {
    loadedByUrl[_url] = loaded;
    const totalLoaded = Object.values(loadedByUrl).reduce((acc, val) => acc + val, 0);
    onTotalProgress(totalLoaded, file.size);
  };

  do {
    const currentChunks = chunks.splice(0, 5);
    if (currentChunks.length === 0) {
      break;
    }

    const results = await Promise.all(
      currentChunks.map(chunk =>
        uploadSingle({
          file: chunk.blob,
          url: [ chunk.url ],
          onProgress: (loaded, total) => onProgress(loaded, total, chunk.url)
        })
      )
    );

    for (const entry of results) {
      if (entry.status !== 200) {
        throw new Error("Failed to upload part");
      }

      if (entry.etags?.[0]) {
        etags.push(entry.etags[0]);
      }
    }
  } while (true); // eslint-disable-line no-constant-condition

  return {
    status: 200,
    etags
  };
}

export function uploadSingle({ file, url, onProgress }: UploadInput): Promise<UploadOutput> {
  const xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const etag = xhr.getResponseHeader('etag')?.replaceAll('"', '') || null;
        const etags = etag ? [ etag ] : [];

        resolve({ status: xhr.status, etags });
      } else {
        reject(xhr.response);
      }
    };

    xhr.onerror = e => reject(e);

    xhr.upload.addEventListener('progress', e => {
      if (!e.lengthComputable) {
        return;
      }

      onProgress(e.loaded, e.total);
    });

    xhr.open('PUT', url[0], true);
    xhr.send(file);
  });
}

export function getMimeTypeByFilename(filename: string) {
  return MIME_TYPES_BY_EXTENSION[getExtensionFromFilename(filename)];
}

export function getMaxFileSizeByMimeType(type: string) {
  return (MAX_SIZE_MB[type] ? MAX_SIZE_MB[type] : MAX_SIZE_MB_DEFAULT) * 1024 * 1024;
}
